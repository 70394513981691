import { Box } from "@mui/material";
import PrivacyAgeStep from "./PrivacyAge/PrivacyAgeStep";
import { useState } from "react";
import ErrorPage from "../../ui/ErrorPage";
import LocationStep from "./Location/LocationStep";
import MutualityStep from "./MutualityStep";
import TopicStep from "./TopicStep";
import TreatmentStep from "./TreatmentStep";
import SubtopicStep from "./SubtopicStep";
import ChooseProfStep from "./ChooseProfStep";
import RegisterStep from "./RegisterStep";
import EndMatchingStep from "./EndMatchingStep";
import { useMutation } from "@apollo/client";
import { graphql } from "../../../api/__generated__";
import LoginStep from "./LoginStep";

export type IntroDetails = {
  professionalId: number;
  startTime: Date;
};

const requestIntroConsultMutation = graphql(`
  mutation RequestIntroConsult($input: RequestConsultationAsHumanInput!) {
    requestConsultationAsHuman(input: $input) {
      id
      professional {
        avatar {
          url
        }
        id
        user {
          firstName
          id
          lastName
        }
      }
      scheduledFrom
    }
  }
`);

const Match = () => {
  const [currentStep, setCurrentStep] = useState<string>("privacy");
  const [previousSteps, setPreviousSteps] = useState<string[]>([]);

  const setNextStep = (step: string) => {
    if (!previousSteps.includes(step)) {
      // To avoid having all switches between login and register be part of the list
      setPreviousSteps([...previousSteps, currentStep]);
    }

    setCurrentStep(step);
  };

  const stepBack = () => {
    const previousStep = previousSteps[previousSteps.length - 1];

    setPreviousSteps(previousSteps.slice(0, -1));
    setCurrentStep(previousStep);
  };

  const [introDetails, setIntroDetails] = useState<IntroDetails>();
  const [requestIntroConsult] = useMutation(requestIntroConsultMutation);

  const bookIntroConsult = async () => {
    if (!introDetails) return;

    await requestIntroConsult({
      variables: {
        input: {
          fromMatching: true,
          professionalId: introDetails.professionalId,
          startTime: introDetails.startTime,
          type: "INTRO",
        },
      },
    });
  };

  const getCurrentMatchingStep = (step: string) => {
    switch (step) {
      case "privacy":
        return <PrivacyAgeStep setNextStep={setNextStep} />;

      case "location":
        return <LocationStep setNextStep={setNextStep} stepBack={stepBack} />;

      case "mutuality":
        return <MutualityStep setNextStep={setNextStep} stepBack={stepBack} />;

      case "topic":
        return <TopicStep setNextStep={setNextStep} stepBack={stepBack} />;

      case "subtopic":
        return <SubtopicStep setNextStep={setNextStep} stepBack={stepBack} />;

      case "treatment":
        return <TreatmentStep setNextStep={setNextStep} stepBack={stepBack} />;

      case "mindlabRedirect":
        // The redirect has already happend this should maybe show a loading spinner or so?
        return undefined;

      case "chooseProf":
        return (
          <ChooseProfStep
            bookIntroConsult={bookIntroConsult}
            setIntroDetails={setIntroDetails}
            setNextStep={setCurrentStep}
          />
        );

      case "register":
        return (
          <RegisterStep
            bookIntroConsult={bookIntroConsult}
            setNextStep={setCurrentStep}
            stepBack={stepBack}
          />
        );

      case "login":
        return (
          <LoginStep
            bookIntroConsult={bookIntroConsult}
            setNextStep={setCurrentStep}
            stepBack={stepBack}
          />
        );

      case "endMatching":
        return <EndMatchingStep />;

      default:
        return <ErrorPage />;
    }
  };

  return <Box>{getCurrentMatchingStep(currentStep)}</Box>;
};

export default Match;

import { Box, Typography, useTheme } from "@mui/material";
import { graphql } from "../../../api/__generated__";
import { useQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "./Common/Header";
import StepHeader from "./Common/StepHeader";
import StepMainContent from "./Common/StepMainContent";
import BloomieCard from "./Common/BloomieCard";
import Select from "react-select";
import useSelectTranslation from "../../../api/languages/useSelectTranslation";
import Footer from "./Common/Footer";
import { Link } from "react-router-dom";

const mutualitiesQuery = graphql(`
  query Mutualities {
    mutualities {
      otherMutualities {
        descriptionTranslationKey
        id
        imgUrl
        linkUrl
        preferred
        titleTranslationKey
      }
      regionMutualities {
        descriptionTranslationKey
        id
        imgUrl
        linkUrl
        preferred
        titleTranslationKey
      }
    }
  }
`);

const submitMutualityStepMutation = graphql(`
  mutation SubmitMutualityStep($mutualityId: String) {
    submitMutualityStep(mutualityId: $mutualityId) {
      matchingToken
      nextStep
    }
  }
`);

const organizationQuery = graphql(`
  query OrganizationForMutuality($emailOrCode: String!) {
    organizationByEmailOrCode(emailOrCode: $emailOrCode) {
      id
      name {
        ...useSelectTranslationStringInput
      }
    }
  }
`);

interface MutualityStepProps {
  setNextStep: (nextStep: string) => void;
  stepBack: () => void;
}

const MutualityStep = ({ setNextStep, stepBack }: MutualityStepProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  type OtherChoice = {
    label: string;
    value: string;
  };

  const orgCode = localStorage.getItem("orgCode");
  const { data: orgData } = useQuery(organizationQuery, {
    variables: { emailOrCode: orgCode || "" },
  });

  const { data: mutualitiesData } = useQuery(mutualitiesQuery);
  const [choice, setChoice] = useState<string | null | undefined>(undefined);
  const [otherChoice, setOtherChoice] = useState<
    OtherChoice | null | undefined
  >(undefined);

  const [submitMutualityStep] = useMutation(submitMutualityStepMutation);
  const selectTranslation = useSelectTranslation();

  const preferredMutualities =
    mutualitiesData?.mutualities.regionMutualities.filter((e) => e.preferred);

  const regularMutualities =
    mutualitiesData?.mutualities.regionMutualities.filter((e) => !e.preferred);

  return (
    <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
      <Header />
      <StepHeader
        imageUrl="https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D64-idea%2C+Size%3DSmall.png"
        subtitle={
          orgCode
            ? t("match:mutualityStep.subTitle", {
                orgName: selectTranslation(
                  orgData?.organizationByEmailOrCode?.name,
                ),
              })
            : t("match:mutualityStep.subTitle_B2C")
        }
        title={t("match:mutualityStep.title")}
      />
      <StepMainContent
        enableNext={choice !== undefined && otherChoice !== undefined}
        onClickNext={async () => {
          const { data: submitData } = await submitMutualityStep({
            variables: {
              mutualityId: choice ? choice : otherChoice?.value,
            },
          });

          submitData?.submitMutualityStep.nextStep &&
            setNextStep(submitData.submitMutualityStep.nextStep);

          submitData?.submitMutualityStep.matchingToken &&
            localStorage.setItem(
              "matchingToken",
              submitData.submitMutualityStep.matchingToken,
            );
        }}
        onClickPrevious={stepBack}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            justifyContent: "center",
          }}
        >
          {preferredMutualities && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "35px 62px",
                justifyContent: "center",
                maxWidth: "875px",
                paddingY: "50px",
              }}
            >
              {preferredMutualities.map((mutuality) => (
                <BloomieCard
                  bodyChildren={
                    <Box>
                      <Typography
                        className="bodyText"
                        sx={{
                          "&:hover": {
                            color:
                              choice === mutuality.id
                                ? undefined
                                : theme.palette.black.main,
                          },
                          color:
                            choice === mutuality.id
                              ? theme.palette.black.main
                              : theme.palette.black.light,
                          fontSize: "12px",
                          fontWeight: 400,
                          transition: "color 0.3s ease-out",
                        }}
                      >
                        {t("match:mutualities.preferred.descAddon")}
                      </Typography>
                    </Box>
                  }
                  bodyText={
                    orgCode
                      ? t(mutuality.descriptionTranslationKey, {
                          orgName: selectTranslation(
                            orgData?.organizationByEmailOrCode?.name,
                          ),
                        })
                      : t("match:mutualities.helan.desc_B2C")
                  }
                  checked={choice === mutuality.id}
                  footerChildren={
                    <Link
                      style={{ fontSize: "12px" }}
                      target="_blank"
                      to={mutuality.linkUrl}
                    >
                      {t("match:bloomieCard.moreInfo")}
                    </Link>
                  }
                  imgUrl={mutuality.imgUrl}
                  key={mutuality.id}
                  onClick={() => {
                    setChoice(mutuality.id);
                    setOtherChoice(null);
                  }}
                  size="lg"
                  type={"outlined"}
                />
              ))}
            </Box>
          )}
          {regularMutualities && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "35px 62px",
                justifyContent: "center",
                maxWidth: "875px",
              }}
            >
              {regularMutualities.map((mutuality) => (
                <BloomieCard
                  checked={choice === mutuality.id}
                  footerChildren={
                    <Link
                      style={{ fontSize: "12px" }}
                      target="_blank"
                      to={mutuality.linkUrl}
                    >
                      {t("match:bloomieCard.moreInfo")}
                    </Link>
                  }
                  imgUrl={mutuality.imgUrl}
                  key={mutuality.id}
                  logoImg
                  onClick={() => {
                    setChoice(mutuality.id);
                    setOtherChoice(null);
                  }}
                  type={"outlined"}
                />
              ))}
              <BloomieCard
                bodyText={t("match:mutualityStep.dontKnow")}
                checked={choice === null && otherChoice === null}
                imgUrl={
                  "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D63-dont-know%2C+Size%3DSmall.png"
                }
                key={0}
                onClick={() => {
                  setChoice(null);
                  setOtherChoice(null);
                }}
                type={"outlined"}
              />
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              justifyContent: "center",
              maxWidth: "875px",
              padding: "50px 0px 20px 0px",
            }}
          >
            <Typography variant="h2">{t("match:mutualities.other")}</Typography>
            <Select
              onChange={(e) => {
                setOtherChoice(e);
                setChoice(null);
              }}
              options={mutualitiesData?.mutualities.otherMutualities.map(
                (mutuality) => {
                  return {
                    label: t(mutuality.titleTranslationKey),
                    value: mutuality.id,
                  };
                },
              )}
              placeholder="Selecteer een andere mutualiteit"
              styles={{
                control: () => ({
                  border: "2px solid",
                  borderRadius: 100,
                  display: "flex",
                  fontSize: "16px",
                  padding: "4px 8px",
                  textAlign: "left",
                }),
                indicatorSeparator: () => ({
                  display: "none",
                }),
                menu: (defaultValue) => ({
                  ...defaultValue,
                  border: "1px solid",
                }),
              }}
              value={otherChoice}
            />
          </Box>
        </Box>
      </StepMainContent>
      <Footer />
    </Box>
  );
};

export default MutualityStep;

import { Box, useTheme } from "@mui/material";
import { WaveBox } from "wave-box";
import { IWithChildren } from "../../../../baseInterfaces";
import Button from "../../../ui/buttons/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";
import {
  I18Namespaces,
  BloomUpNamespacesEnum,
} from "../../../language/I18Namespaces";
import { useEffect } from "react";

interface StepMainContentProps extends IWithChildren {
  enableNext?: boolean;
  onClickNext?: () => void;
  onClickPrevious?: () => void;
}

const StepMainContent = ({
  children,
  onClickNext,
  enableNext = false,
  onClickPrevious,
}: StepMainContentProps) => {
  const theme = useTheme();
  const { t } = useTranslation<I18Namespaces>([BloomUpNamespacesEnum.Match]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <WaveBox background={"white"} fillBackground={true}>
      <Box
        sx={{
          "@media screen and (max-width: 500px)": {
            padding: "1rem",
          },
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          [theme.breakpoints.down(600)]: {
            gap: "0.5rem",
          },
          justifyContent: "center",
          padding: "3rem",
        }}
      >
        {children}
        <Box
          sx={{
            "@media screen and (max-width: 500px)": {
              marginBottom: "1rem",
            },
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            [theme.breakpoints.down(500)]: {
              flexDirection: "column-reverse",
              gap: "1rem",
            },
            gap: "30px",
            justifyContent: "center",
            marginBottom: "2.5rem",
          }}
        >
          {onClickPrevious && (
            <Button
              icon={<ArrowBackIosIcon />}
              label={t("stepMainContent.previous")}
              onClick={() => {
                onClickPrevious();
              }}
              sx={{ color: "black", minWidth: "163px" }}
            />
          )}
          {onClickNext && (
            <Button
              disabled={!enableNext}
              endIcon={<ArrowForwardIosIcon />}
              fullyColored
              label={t("stepMainContent.next")}
              onClick={onClickNext}
              sx={{ minWidth: "163px" }}
            />
          )}
        </Box>
      </Box>
    </WaveBox>
  );
};

export default StepMainContent;

import { Box, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";

const moveForever = keyframes`
    0% { transform: translate3d(-90px, 0, 0) },
    100% { transform: translate3d(85px, 0, 0) }
`;

export const Use = styled("use")({
  animation: `${moveForever} 25s cubic-bezier(.55, .5, .45, .5) infinite`,
});

interface SingleWaveProps {
  reverseWave?: boolean;
  waveColor?: string;
  waveHeight?: string;
}

export const SingleWave = ({
  waveColor = "#fff",
  reverseWave = false,
  waveHeight = "50px",
}: SingleWaveProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: waveHeight,
        transform: reverseWave ? "scale(1, -1)" : "default",
        [theme.breakpoints.down(500)]: {
          height: "20px",
        },
      }}
    >
      <svg
        height="100%"
        preserveAspectRatio="none"
        shapeRendering="auto"
        viewBox="0 24 150 28"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <path
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            id="gentle-wave"
          />
        </defs>
        <g>
          <Use fill={waveColor} x="48" xlinkHref="#gentle-wave" y="7" />
        </g>
      </svg>
    </Box>
  );
};

import { Box } from "@mui/material";
import BloomieCard from "../Common/BloomieCard";
import { useTranslation } from "react-i18next";
import {
  I18Namespaces,
  BloomUpNamespacesEnum,
} from "../../../language/I18Namespaces";

const PrivacyBlock = () => {
  const { t } = useTranslation<I18Namespaces>([BloomUpNamespacesEnum.Match]);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "2rem 3.5rem",
        justifyContent: "space-around",
        margin: "auto",
        marginTop: "1rem",
        maxWidth: "871px",
      }}
    >
      <BloomieCard
        bodyText={t("privacyAgeStep.main.privacy.cards.1.body")}
        imgUrl="https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D51-my-data%2C+Size%3DSmall.png"
        title={t("privacyAgeStep.main.privacy.cards.1.title")}
        type="blank"
      />
      <BloomieCard
        bodyText={t("privacyAgeStep.main.privacy.cards.2.body")}
        imgUrl="https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D52-no-social%2C+Size%3DSmall.png"
        title={t("privacyAgeStep.main.privacy.cards.2.title")}
        type="blank"
      />
      <BloomieCard
        bodyText={t("privacyAgeStep.main.privacy.cards.3.body")}
        imgUrl="https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D53-no-recording%2C+Size%3DSmall.png"
        title={t("privacyAgeStep.main.privacy.cards.3.title")}
        type="blank"
      />
      <BloomieCard
        bodyText={t("privacyAgeStep.main.privacy.cards.4.body")}
        imgUrl="https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D54-old-archive%2C+Size%3DSmall.png"
        title={t("privacyAgeStep.main.privacy.cards.4.title")}
        type="blank"
      />
      <BloomieCard
        bodyText={t("privacyAgeStep.main.privacy.cards.5.body")}
        imgUrl="https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D55-cutting-list%2C+Size%3DSmall.png"
        title={t("privacyAgeStep.main.privacy.cards.5.title")}
        type="blank"
      />
    </Box>
  );
};

export default PrivacyBlock;

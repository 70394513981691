import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LanguageIcon from "@mui/icons-material/Language";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleWave } from "wave-box";
import {
  I18Namespaces,
  BloomUpNamespacesEnum,
} from "../../../language/I18Namespaces";
import {
  I18NexusLanguages,
  languageToCaptionMap,
} from "../../../language/languagesUtil";
import LanguageDropdown from "../../../ui/LanguageDropdown";

const NavigationBar = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Organization,
    BloomUpNamespacesEnum.Match,
  ]);

  const availableLanguages = Object.entries(I18NexusLanguages).map(
    ([_, value]) => {
      return { label: languageToCaptionMap.get(value), value: value };
    },
  );

  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  const [sideMenuLanguageIsOpen, setSideMenuLanguageIsOpen] = useState(false);

  const bloomUpLogoUrl =
    "https://bloomup-production-www.s3.eu-central-1.amazonaws.com/Logo-no-tagline-white.svg";

  const handleSideMenuLanguageClick = () => {
    setSideMenuLanguageIsOpen(!sideMenuLanguageIsOpen);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "#609D76",
          color: theme.palette.white.main,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "50px",
            justifyContent: "space-between",
            maxWidth: "1400px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              padding: "0 1rem",
            }}
          >
            <Box
              alt="logo"
              component="img"
              src={bloomUpLogoUrl}
              sx={{
                "@media screen and(max-width: 500px)": {
                  maxHeight: "25px",
                },
                height: "auto",
                maxHeight: "50px",
                maxWidth: "100%",
                paddingTop: "8px",
              }}
            />

            <Typography
              sx={{
                "@media screen and (max-width: 500px)": {
                  display: "none",
                },
                fontFamily: "Mulish",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 700,
                padding: "0 1rem",
              }}
            >
              {t("match:header.title")}
            </Typography>
          </Box>

          <Box
            sx={() => ({
              alignItems: "center",
              color: "white",
              display: "flex",
              flexDirection: "row",
              marginTop: "6px",
              padding: "0 1rem",
            })}
          >
            <LanguageDropdown textColor={theme.palette.white.main} />
          </Box>
        </Box>
      </Box>
      <SingleWave reverseWave waveColor={"#609D76"} />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          marginTop: "-12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            maxWidth: "1400px",
            width: "100%",
          }}
        >
          <Drawer
            anchor={"right"}
            onClose={() => setSideMenuIsOpen(false)}
            open={sideMenuIsOpen}
          >
            <List>
              <ListItemButton>
                <ListItemIcon onClick={() => setSideMenuIsOpen(false)}>
                  <HighlightOffIcon
                    sx={(theme) => ({
                      ...theme.typography.h4,
                      color: theme.palette.white.main,
                    })}
                  />
                </ListItemIcon>
              </ListItemButton>
              <Divider />
              <ListItemButton onClick={handleSideMenuLanguageClick}>
                <ListItemIcon>
                  <LanguageIcon sx={{ color: theme.palette.black.main }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="h4">
                    {t("organization:landing.navbar.language", "Taal")}
                  </Typography>
                </ListItemText>
                {sideMenuLanguageIsOpen ? (
                  <ExpandLess sx={{ color: theme.palette.black.main }} />
                ) : (
                  <ExpandMore sx={{ color: theme.palette.black.main }} />
                )}
              </ListItemButton>
              <Collapse
                in={sideMenuLanguageIsOpen}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {availableLanguages.map((language) => (
                    <ListItemButton
                      key={language.label}
                      sx={{ paddingLeft: "1.5rem" }}
                    >
                      <ListItemText
                        onClick={() => {
                          i18n.changeLanguage(language.value);
                        }}
                      >
                        <Typography variant="h4">{language.label}</Typography>
                      </ListItemText>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </List>
          </Drawer>
        </Box>
      </Box>
    </Box>
  );
};

export default NavigationBar;

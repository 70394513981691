import { Box, Typography } from "@mui/material";

const EndMatchingStep = () => {
  return (
    <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
      <Typography variant="h1"> END MATCHING </Typography>
    </Box>
  );
};

export default EndMatchingStep;

/* eslint-disable react-hooks/exhaustive-deps */

import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useScrollToTop from "../../hooks/useScrollToTop";
import useStorage from "../../hooks/useStorage";
import testimony1 from "../../illustrations/testimony1.png";
import testimony2 from "../../illustrations/testimony2.png";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import LoadingPage from "../../layout/LoadingPage";
import PartnerPageContentBlocks, {
  PartnerPageContentBlock,
  PartnerPageContentBlockTypes,
} from "./components/PartnerPageContentBlocks";
import PartnerPageFooter from "./components/PartnerPageFooter";
import PartnerPageHeader from "./components/PartnerPageHeader";
import LandingPageContext from "./context/LandingPageContext";
import { ILandingPageContext } from "./context/organization";

const PREFIX = "GenericOrganizationPage";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledContainer = styled(Container)(() => ({
  [`&.${classes.root}`]: { maxWidth: "none", padding: 0 },
}));

const GenericOrganizationPage = () => {
  const { slug: orgNameFromURL } = useParams();
  const { organization, slug, setSlug } =
    useContext<ILandingPageContext>(LandingPageContext);

  const { removeMatchingTokenFromStorage } = useStorage();

  useScrollToTop();

  useEffect(() => {
    if (orgNameFromURL && orgNameFromURL !== slug) setSlug(orgNameFromURL);
  }, [slug, orgNameFromURL]);

  useEffect(() => {
    // Remove matching token to make sure we don't use a matchingtoken that was used in another flow
    const removeMatchingToken = async () => {
      await removeMatchingTokenFromStorage();
      console.log("Matching token should have been removed.");
    };

    removeMatchingToken();
  }, []);

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Partners,
    BloomUpNamespacesEnum.Common,
  ]);

  if (!organization) return <LoadingPage full />;

  //TODO: Remove this and move colors back to CSS / Styles.
  const backgroundColor: string =
    organization.getTranslation(translate, "blocks.backgroundcolor") ?? "";

  const orgColor: string =
    organization.getTranslation(translate, "color") ?? "#000000";

  const rightBlocks: Array<PartnerPageContentBlock> = [
    {
      backgroundColor,
      color: orgColor,
      description: organization.getTranslation(
        translate,
        "blocks.privacy.description",
      ),
      title: organization.getTranslation(translate, "blocks.privacy.title"),
      type: PartnerPageContentBlockTypes.text,
    },
    {
      backgroundColor,
      color:
        organization.getTranslation(translate, "blocks.matching.bordercolor") ??
        "",
      description: organization.getTranslation(
        translate,
        "blocks.matching.description",
      ),
      title: organization.getTranslation(translate, "blocks.matching.title"),
      type: PartnerPageContentBlockTypes.matching,
    },
  ];

  const leftBlocks: Array<PartnerPageContentBlock> = [
    {
      backgroundColor,
      color: orgColor,
      description: organization.getTranslation(
        translate,
        "blocks.about.description",
      ),
      title: organization.getTranslation(translate, "blocks.about.title"),
      type: PartnerPageContentBlockTypes.text,
    },
    {
      backgroundColor,

      color: orgColor,
      description: JSON.parse(
        organization.getTranslation(translate, "blocks.howto.description"),
      ),
      title: organization.getTranslation(
        translate,
        "blocks.howto.title",
        "Hoe werkt het?",
      ),
      type: PartnerPageContentBlockTypes.list,
    },
  ];

  //TODO: Misschien anders aanpakken.
  if (slug === "mantelzorgstadantwerpen") {
    leftBlocks.push({
      avatarSrc: testimony1,
      backgroundColor,
      color:
        organization.getTranslation(
          translate,
          "blocks.testimony1.bordercolor",
        ) ?? "",
      description: organization.getTranslation(
        translate,
        "blocks.testimony1.description",
      ),
      title: organization.getTranslation(translate, "blocks.testimony1.name"),
      type: PartnerPageContentBlockTypes.review,
    });
    rightBlocks.push({
      avatarSrc: testimony2,
      backgroundColor,
      color:
        organization.getTranslation(
          translate,
          "blocks.testimony2.bordercolor",
        ) ?? "",
      description: organization.getTranslation(
        translate,
        "blocks.testimony2.description",
      ),
      title: organization.getTranslation(translate, "blocks.testimony2.name"),
      type: PartnerPageContentBlockTypes.review,
    });
  }

  if (
    slug &&
    [
      "octaplus",
      "idewe",
      "assaabloy",
      "euroshoe",
      "advn",
      "aaltra",
      "mantelzorggent",
    ].includes(slug)
  ) {
    // Matching block
    const movedBlock = rightBlocks.pop();

    if (movedBlock && slug !== "mantelzorggent") leftBlocks.push(movedBlock);

    rightBlocks.unshift({
      backgroundColor: "red",
      color:
        organization.getTranslation(
          translate,
          "blocks.testimony1.bordercolor",
        ) ?? "",
      description: organization.getTranslation(
        translate,
        "blocks.ggt.description",
        "Zet de stap naar een gezonde werk-privébalans. Je leert je werk beter los te laten in je vrije tijd om zo de negatieve gevolgen zoals stress te vermijden. Zo geniet je beter van je privéleven en word je energieker, blijer en effectiever op je werk.<br /><br />Start deze zelfhulp module:",
      ),
      title: organization.getTranslation(
        translate,
        "blocks.ggt.title",
        "Nieuw! <br />Leer beter deconnecteren via onze zelfhulp app",
      ),
      type: PartnerPageContentBlockTypes.ggt,
    });
  }

  return (
    <StyledContainer className={classes.root} maxWidth="md">
      <PartnerPageHeader
        color={orgColor}
        description={organization.getTranslation(
          translate,
          "header.description",
          "Heb je nood aan mentale ondersteuning?<br/>BloomUp verbindt je snel en veilig met een online psycholoog of psychotherapeut.",
        )}
        image={`https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/PARTNERS/${slug}/header.jpg`}
        logo={{
          source: `https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/PARTNERS/${slug}/logo.png`,
          width: 201,
        }}
        title={organization.getTranslation(
          translate,
          "header.title",
          "Blij je te zien!",
        )}
      />
      <PartnerPageContentBlocks
        color={orgColor}
        leftBlocks={leftBlocks}
        rightBlocks={rightBlocks}
      />

      <PartnerPageFooter
        color={orgColor}
        description={organization.getTranslation(
          translate,
          "footer.description",
        )}
        title={organization.getTranslation(translate, "footer.title", "")}
      />
    </StyledContainer>
  );
};

export default GenericOrganizationPage;

import { Box, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import CustomTextField from "../../ui/form/CustomTextField";
import FormLabel from "../../ui/form/FormLabel";
import Button from "../../ui/buttons/Button";
import { graphql } from "../../../api/__generated__";
import { useMutation } from "@apollo/client";
import { useContext, useState } from "react";
import AuthContext from "../../providers/auth/AuthContext";
import ToastContext from "../../providers/toast/ToastContext";

const loginMutation = graphql(`
  mutation LoginInMatching($input: LoginV2Input!) {
    loginV2(input: $input) {
      ... on TokenResponse {
        accessToken
        refreshToken
      }
      ... on LoginV2FailureResponse {
        reason
      }
    }
  }
`);

const LoginStep = ({
  bookIntroConsult,
  setNextStep,
  stepBack,
}: {
  bookIntroConsult: () => Promise<void>;
  setNextStep: (nextStep: string) => void;
  stepBack: () => void;
}) => {
  const { t } = useTranslation();
  const [login] = useMutation(loginMutation);
  const { internalAppLogin } = useContext(AuthContext);
  const { setToast, close: closeToast } = useContext(ToastContext);
  const [show2FA, setShow2FA] = useState(false);

  return (
    <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
      <Typography variant="h1"> LOGIN </Typography>
      <Formik
        initialValues={{ email: "", password: "", token2FA: "" }}
        onSubmit={async (values) => {
          closeToast();

          const { data } = await login({
            variables: {
              input: {
                email: values.email,
                password: values.password,
              },
            },
          });

          if (!data) {
            setShow2FA(false);
            setToast({
              message: t("api_errors:login.failure"),
              noHide: true,
              severity: "warning",
            });
          } else if (data.loginV2.__typename === "LoginV2FailureResponse") {
            if (data.loginV2.reason === "INCORRECT_CREDENTIALS") {
              setShow2FA(false);
              setToast({
                message: t("api_errors:login.failure"),
                noHide: true,
                severity: "warning",
              });
            } else if (data.loginV2.reason === "NEEDS_2FA") {
              setShow2FA(true);
            } else {
              setShow2FA(true);
              setToast({
                message: t("api_errors:login.2fa_failure"),
                noHide: true,
                severity: "warning",
              });
            }
          } else if (data.loginV2.__typename === "TokenResponse") {
            // Temporary fallback while mindlabToken is optional on the graph
            if (!data.loginV2.accessToken || !data.loginV2.refreshToken) {
              setToast({
                message: t("api_errors:login.failure"),
                noHide: true,
                severity: "warning",
              });

              return;
            }

            await internalAppLogin(
              data.loginV2.accessToken,
              data.loginV2.refreshToken,
            );

            await bookIntroConsult();
            setNextStep("endMatching");
          }
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required(t("validation:email.mandatory"))
            .email(t("validation:email.validity")),
          password: Yup.string().required(t("validation:password.mandatory")),
          token2FA: Yup.string(),
        })}
      >
        {({
          handleSubmit,
          errors,
          handleBlur,
          values,
          handleChange,
          submitForm,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              {show2FA ? (
                <>
                  <FormLabel label={t("common:token2fa")} />
                  <CustomTextField
                    autoComplete="one-time-code"
                    autoFocus
                    error={errors.token2FA}
                    id="token2FA"
                    name="token2FA"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={(e) => (e.key === "Enter" ? submitForm() : null)}
                    sx={{
                      marginBottom: 4,
                    }}
                    type="text"
                    value={values.token2FA}
                  />
                </>
              ) : (
                <>
                  <Box>
                    <FormLabel label={t("common:email")} />
                    <CustomTextField
                      autoComplete="email"
                      autoFocus
                      error={errors.email}
                      id="email"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                    />
                  </Box>
                  <Box>
                    <FormLabel label={t("common:password")} />
                    <CustomTextField
                      autoComplete="password"
                      error={errors.password}
                      id="password"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onKeyDown={(e) =>
                        e.key === "Enter" ? submitForm() : null
                      }
                      type="password"
                      value={values.password}
                    />
                  </Box>
                </>
              )}
              <Button
                bloomColor="green"
                label={t("common:login")}
                onClick={() => submitForm()}
                type="submit"
              />
            </form>
          );
        }}
      </Formik>
      <Button
        bloomColor="bloom"
        label="I wat to register"
        onClick={() => setNextStep("register")}
      />
      <Button label="back" onClick={() => stepBack()} />
    </Box>
  );
};

export default LoginStep;

import { Box, Typography, useTheme } from "@mui/material";
import { graphql } from "../../../api/__generated__";
import { useQuery } from "@apollo/client";
import useSelectTranslation from "../../../api/languages/useSelectTranslation";
import Button from "../../ui/buttons/Button";
import { IntroDetails } from "./Match";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../providers/auth/AuthContext";
import StepHeader from "./Common/StepHeader";
import StepMainContent from "./Common/StepMainContent";
import Footer from "./Common/Footer";
import { useTranslation } from "react-i18next";

const proposedMatchesQuery = graphql(`
  query ProposedMatches {
    proposedMatches {
      availabilityV2(amountOfDaysInTheFuture: 31, consultationType: INTRO)
      avatar {
        url
      }
      experienceSince
      gender
      id
      isOnline
      profileDescription {
        ...useSelectTranslationStringInput
      }
      standardDuration
      standardRate
      type
      user {
        firstName
        id
        lastName
      }
    }
  }
`);

const selectedSubtopicQuery = graphql(`
  query SelectedSubtopic {
    selectedSubtopic {
      id
      titleTranslationKey
    }
  }
`);

const organizationQuery = graphql(`
  query OrganizationForMatching($emailOrCode: String!) {
    organizationByEmailOrCode(emailOrCode: $emailOrCode) {
      coachingsPerHuman
      consultsPerHuman
      id
      name {
        ...useSelectTranslationStringInput
      }
    }
  }
`);

const ChooseProfStep = ({
  setIntroDetails,
  setNextStep,
  bookIntroConsult,
}: {
  bookIntroConsult: () => Promise<void>;
  setIntroDetails: (introDetails: IntroDetails) => void;
  setNextStep: (nextStep: string) => void;
}) => {
  const { data: matchesData, loading } = useQuery(proposedMatchesQuery, {
    fetchPolicy: "network-only",
  });

  const [timeoutCompleted, setTimeoutCompleted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTimeoutCompleted(true);
    }, 4000);
  }, []);

  const { currentUser } = useContext(AuthContext);
  const localCode = localStorage.getItem("orgCode");

  const { data: organizationData } = useQuery(organizationQuery, {
    skip: !localCode,
    variables: { emailOrCode: localCode ?? "" },
  });

  const { data: subTopicData } = useQuery(selectedSubtopicQuery, {
    fetchPolicy: "network-only",
  });

  const theme = useTheme();
  const { t } = useTranslation();
  const selectTranslation = useSelectTranslation();

  if (!timeoutCompleted || loading) {
    return (
      <>
        <StepHeader
          imageUrl={
            "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D48-talk-to-professional%2C+Size%3DSmall.png"
          }
          subtitle={
            subTopicData?.selectedSubtopic?.titleTranslationKey &&
            t(subTopicData?.selectedSubtopic?.titleTranslationKey)
          }
          title={t("match:treatmentStep.treatments.coaching.title")}
        />
        <StepMainContent>
          <Box
            component={"img"}
            src="https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D76-selfhelp%2C+Size%3DSmall.png"
            sx={{
              maxHeight: "320px",
              maxWidth: "520px",
              [theme.breakpoints.down(500)]: {
                maxHeight: "190px",
                maxWidth: "312px",
              },
            }}
          />
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h2">
              {t("match:loadingStep.body.title")}
            </Typography>
            <Typography variant="body2">
              {t("match:loadingStep.body.caption")}
            </Typography>
          </Box>
        </StepMainContent>
        <Footer />
      </>
    );
  } else {
    return (
      <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
        <Typography variant="h1"> CHOOSE PROF </Typography>
        {matchesData?.proposedMatches.map((professional) => (
          <>
            <h2>
              {professional.user.firstName} {professional.user.lastName}
            </h2>
            <p>{professional.gender}</p>
            <p>{selectTranslation(professional.profileDescription)}</p>
            {organizationData ? (
              <p>
                {organizationData.organizationByEmailOrCode?.consultsPerHuman}{" "}
                zoveel consults betaald door bedrijf
              </p>
            ) : undefined}
            {professional.availabilityV2.map((availability) => (
              <Button
                label={availability}
                onClick={() => {
                  setIntroDetails({
                    professionalId: professional.id,
                    startTime: new Date(availability),
                  });
                }}
              />
            ))}
          </>
        ))}
        <br />
        <br />
        <br />
        <Button
          label="submit"
          onClick={async () => {
            if (currentUser) {
              await bookIntroConsult();

              setNextStep("endMatching");
            } else {
              setNextStep("register");
            }
          }}
        />
      </Box>
    );
  }
};

export default ChooseProfStep;

import { Box, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import CustomTextField from "../../ui/form/CustomTextField";
import FormLabel from "../../ui/form/FormLabel";
import Button from "../../ui/buttons/Button";
import { graphql } from "../../../api/__generated__";
import { useMutation } from "@apollo/client";
import { useContext } from "react";
import AuthContext from "../../providers/auth/AuthContext";
import { prepareOneLanguageForAPI } from "../../language/languagesUtil";
import ToastContext from "../../providers/toast/ToastContext";

const registerMutation = graphql(`
  mutation RegisterInMatching($input: RegisterHumanInput!) {
    registerHumanV2(input: $input) {
      accessToken
      refreshToken
    }
  }
`);

const RegisterStep = ({
  bookIntroConsult,
  setNextStep,
  stepBack,
}: {
  bookIntroConsult: () => Promise<void>;
  setNextStep: (nextStep: string) => void;
  stepBack: () => void;
}) => {
  const { t, i18n } = useTranslation();
  const [register] = useMutation(registerMutation);
  const { internalAppLogin } = useContext(AuthContext);
  const { setToast, close: closeToast } = useContext(ToastContext);

  return (
    <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
      <Typography variant="h1"> REGISTER </Typography>
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={async (values) => {
          closeToast();

          const { data, errors } = await register({
            variables: {
              input: {
                email: values.email,
                password: values.password,
                preferredLanguage: prepareOneLanguageForAPI(i18n.language),
              },
            },
          });

          if (
            errors?.[0].message === "api_errors:human.registration.email_in_use"
          ) {
            setToast({
              message: t("api_errors:human.registration.email_in_use"),
              noHide: true,
              severity: "warning",
            });

            return;
          } else if (
            errors?.[0].message === "api_errors:human.registration.failed"
          ) {
            setToast({
              message: t("api_errors:human.registration.failed"),
              noHide: true,
              severity: "warning",
            });

            return;
          }

          if (
            !data?.registerHumanV2.accessToken ||
            !data.registerHumanV2.refreshToken
          ) {
            setToast({
              message: "ERROR",
              noHide: true,
              severity: "warning",
            });

            return;
          }

          await internalAppLogin(
            data.registerHumanV2.accessToken,
            data.registerHumanV2.refreshToken,
          );

          await bookIntroConsult();
          setNextStep("endMatching");
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required(t("validation:email.mandatory"))
            .email(t("validation:email.validity")),
          password: Yup.string()
            .required(t("validation:password.mandatory"))
            .min(16, t("validation:password.strength")),
        })}
      >
        {({
          handleSubmit,
          errors,
          handleBlur,
          values,
          handleChange,
          submitForm,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box>
                <FormLabel label={t("common:email")} />
                <CustomTextField
                  autoComplete="email"
                  error={errors.email}
                  id="email"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                />
              </Box>

              <Box>
                <FormLabel label={t("common:password")} />
                <CustomTextField
                  autoComplete="password"
                  error={errors.password}
                  id="password"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={(e) => (e.key === "Enter" ? submitForm() : null)}
                  type="password"
                  value={values.password}
                />
              </Box>
              <Button
                bloomColor="green"
                label={t("common:register")}
                onClick={() => submitForm()}
                type="submit"
              />
            </form>
          );
        }}
      </Formik>
      <Button
        bloomColor="bloom"
        label="I wat to login"
        onClick={() => setNextStep("login")}
      />

      <Button label="back" onClick={() => stepBack()} />
    </Box>
  );
};

export default RegisterStep;

import { Link, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { WaveBox } from "wave-box";
import { UseSelectTranslationStringInputFragment } from "../../../../api/languages/fragments";
import useSelectTranslation from "../../../../api/languages/useSelectTranslation";
import { makeFragmentData } from "../../../../api/__generated__";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import LanguageDropdown from "../../../ui/LanguageDropdown";
import BloomUpLogoWithTag from "../../../illustrations/logos/BloomUpLogoWithTag";

const termsUrls = makeFragmentData(
  {
    en: "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/DOCS/Algemene+Voorwaarden+BloomUp.pdf",
    fr: "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/DOCS/Algemene+Voorwaarden+BloomUp.pdf",
    nl: "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/DOCS/Algemene+Voorwaarden+BloomUp.pdf",
  },
  UseSelectTranslationStringInputFragment,
);

interface FooterProps {
  atomiumText?: boolean;
}

const Footer = ({ atomiumText }: FooterProps) => {
  const selectTranslation = useSelectTranslation();
  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Organization,
    BloomUpNamespacesEnum.Match,
  ]);

  const theme = useTheme();

  return (
    <WaveBox
      background={theme.palette.bloom.light}
      bottomWave={false}
      fillBackground
      sx={{ paddingTop: "-50px" }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.bloom.light,
          display: "flex",
          justifyContent: "center",
          padding: "0 6rem 3rem",
          [theme.breakpoints.down(600)]: {
            padding: "0 3rem 3rem",
          },
          [theme.breakpoints.down(400)]: {
            padding: "0 2rem 2rem",
          },
          [theme.breakpoints.down(280)]: {
            padding: "0 1rem 2rem",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                marginRight: "2rem",
                marginTop: "2rem",
                [theme.breakpoints.down(500)]: {
                  marginTop: "1rem",
                },
              }}
            >
              <BloomUpLogoWithTag />
              <Box
                sx={{
                  marginTop: "2rem",
                  [theme.breakpoints.down(500)]: {
                    marginTop: "1rem",
                  },
                }}
              >
                <Typography>
                  {t("landing.footer.address") + "  "}
                  <Link
                    href="mailto:support@bloomup.org"
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                      color: theme.palette.text.primary,
                      fontFamily: theme.typography.fontFamily,
                      fontSize: "16px",
                      fontStyle: "normal",
                      lineHeight: "150%",
                      marginBottom: "0.5rem",
                      textDecoration: "underline",
                    }}
                    underline="hover"
                  >
                    {"support@bloomup.org"}
                  </Link>
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Link
                    href={
                      selectTranslation(termsUrls) ??
                      "https://docs.google.com/document/d/1ZlGkF5ms9YilqQhgd91HrLay9BtcHKWiCejGG-3eehE/edit"
                    }
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                      color: theme.palette.text.primary,
                      fontFamily: theme.typography.fontFamily,
                      fontSize: "16px",
                      fontStyle: "normal",
                      lineHeight: "150%",
                      marginBottom: "0.5rem",
                      marginRight: "1rem",
                      textDecoration: "underline",
                    }}
                    underline="hover"
                  >
                    {t("organization:landing.footer.terms", "Voorwaarden")}
                  </Link>
                  {atomiumText && (
                    <Typography>{t("match:footer.atomium")}</Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                marginTop: "2rem",
              }}
            >
              <LanguageDropdown />
            </Box>
          </Box>
        </Box>
      </Box>
    </WaveBox>
  );
};

export default Footer;

import { Box, Typography, useTheme } from "@mui/material";

interface StepHeaderProps {
  imageUrl: string;
  subtitle?: string;
  title: string;
}

const StepHeader = ({ imageUrl, subtitle, title }: StepHeaderProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        [theme.breakpoints.down(500)]: {
          padding: "1rem 0.5rem 0.5rem",
        },
      }}
    >
      <Box
        alt="graphic"
        component="img"
        src={imageUrl}
        sx={{
          marginY: "1rem",
          maxHeight: "160px",
          maxWidth: "250px",
          [theme.breakpoints.down(500)]: {
            maxHeight: "85px",
            maxWidth: "133px",
          },
        }}
      />
      <Typography sx={{ textAlign: "center" }} variant={"h3"}>
        {title}
      </Typography>
      {subtitle && (
        <Typography sx={{ textAlign: "center", whiteSpace: "pre-line" }}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default StepHeader;

import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { graphql } from "../../../../api/__generated__";
import { useSearchParams } from "react-router-dom";
import Header from "../Common/Header";
import StepHeader from "../Common/StepHeader";
import StepMainContent from "../Common/StepMainContent";
import AgeRangeSlider from "../../../ui/form/AgeRangeSlider";
import Footer from "../Common/Footer";
import PrivacyBlock from "./PrivacyBlock";
import Arrow from "./Arrow.svg";
import { useTranslation } from "react-i18next";
import {
  I18Namespaces,
  BloomUpNamespacesEnum,
} from "../../../language/I18Namespaces";
import SanitizedText from "../../../ui/text/SanitizedText";

interface PrivacyStepProps {
  setNextStep: (nextStep: string) => void;
}

const startMatchingMutation = graphql(`
  mutation StartMatchingV2($input: StartMatchingV2Input!) {
    startMatchingV2(input: $input) {
      matchingToken
      nextStep
    }
  }
`);

const PrivacyAgeStep = ({ setNextStep }: PrivacyStepProps) => {
  const [startMatchingV2] = useMutation(startMatchingMutation);
  const [searchParams, setSearchParams] = useSearchParams();
  const paramCode = searchParams.get("code");
  const localCode = localStorage.getItem("orgCode");
  const [age, setAge] = useState<number>(16);
  const [terms, setTerms] = useState<boolean>(false);
  const theme = useTheme();
  const { t } = useTranslation<I18Namespaces>([BloomUpNamespacesEnum.Match]);

  useEffect(() => {
    if (paramCode) {
      localStorage.setItem("orgCode", paramCode);
      searchParams.delete("code");
      setSearchParams(searchParams);
    }
  }, [paramCode, searchParams, setSearchParams]);

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          height: "100%",
          textAlign: "center",
          width: "100%",
        }}
      >
        <Header />
        <StepHeader
          imageUrl={
            "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D50-ages%2C+Size%3DSmall.png"
          }
          subtitle={t("privacyAgeStep.header.subtitle")}
          title={t("privacyAgeStep.header.title")}
        />
        <StepMainContent
          enableNext={age >= 16 && terms}
          onClickNext={async () => {
            const { data } = await startMatchingV2({
              variables: { input: { age, orgCode: localCode } },
            });

            data?.startMatchingV2.nextStep &&
              setNextStep(data.startMatchingV2.nextStep);

            data?.startMatchingV2.matchingToken &&
              localStorage.setItem(
                "matchingToken",
                data.startMatchingV2.matchingToken,
              );
          }}
        >
          <Box
            sx={{
              "@media screen and (max-width: 500px)": {
                gap: "1rem",
                padding: "2rem 0.5rem 0",
              },
              alignItems: "center",
              display: "Flex",
              flexDirection: "column",
              gap: "3rem",
              padding: "0 1rem 0",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
              }}
            >
              {t("privacyAgeStep.main.slider.title", { age })}
            </Typography>
            <Box width="100%">
              <AgeRangeSlider
                customThumb
                defaultValue={16}
                min={0}
                name={"age"}
                onChange={(e) => {
                  setAge(e);
                }}
                value={age}
              />
              <Box sx={{}}>
                {age < 16 ? (
                  <Alert icon={false} severity="error">
                    <AlertTitle>{t("human:onboarding.age.alert")}</AlertTitle>
                    <SanitizedText input={t("human:onboarding.age.referral")} />
                  </Alert>
                ) : (
                  <Typography sx={{ marginTop: "10px" }}>
                    {t("privacyAgeStep.main.slider.subTitle")}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                "@media screen and (max-width: 500px)": {
                  marginTop: "0.5rem",
                },
                alignItems: "flex-start",
                display: "flex",
                marginTop: "1.5rem",
              }}
            >
              <Box
                sx={{
                  alignItems: "flex-start",
                  display: "flex",
                }}
              >
                <Box
                  component="img"
                  src={Arrow}
                  sx={{
                    "@media screen and (max-width: 800px)": {
                      display: "none",
                    },
                    bottom: "-130px",
                    fill: theme.palette.green.dark,
                    left: "-90px",
                    maxHeight: "150px",
                    position: "relative",
                    zIndex: 1,
                  }}
                />
                <Checkbox
                  onChange={(e) => setTerms(e.target.checked)}
                  size="large"
                  sx={{
                    "@media screen and (max-width: 500px)": {
                      height: "30px",
                      marginRight: "0.5rem",
                      paddingX: "3px",
                      top: "-2px",
                      width: "30px",
                    },
                    height: "20px",
                    marginRight: "1rem",
                    padding: 0,
                    top: "2px",
                    width: "20px",
                  }}
                  value={terms}
                />
              </Box>

              <Box
                sx={{
                  alignItems: "flex-start",
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "100%",
                }}
              >
                <Typography>
                  {t("privacyAgeStep.main.privacy.title")}
                </Typography>
                <List
                  sx={{
                    fontSize: "16px",
                    listStyleType: "disc",
                    paddingLeft: "1.2em",
                    paddingTop: 0,
                  }}
                >
                  <ListItem
                    sx={{
                      "@media screen and (max-width: 500px)": {
                        paddingRight: 0,
                      },
                      display: "list-item",
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: "1rem",
                    }}
                  >
                    <ListItemText
                      primary={
                        <SanitizedText
                          input={t("privacyAgeStep.main.privacy.terms")}
                        />
                      }
                    />
                  </ListItem>
                  <ListItem
                    sx={{
                      "@media screen and (max-width: 500px)": {
                        paddingRight: 0,
                      },
                      display: "list-item",
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: "1rem",
                      paddingTop: 0,
                    }}
                  >
                    <ListItemText
                      primary={
                        <SanitizedText
                          input={t("privacyAgeStep.main.privacy.data")}
                        />
                      }
                    />
                  </ListItem>
                  <ListItem
                    sx={{
                      "@media screen and (max-width: 500px)": {
                        paddingRight: 0,
                      },
                      display: "list-item",
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: "1rem",
                      paddingTop: 0,
                    }}
                  >
                    <ListItemText
                      primary={
                        <SanitizedText
                          input={t("privacyAgeStep.main.privacy.medicalData")}
                        />
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Box>
        </StepMainContent>
        <PrivacyBlock />
      </Box>
      <Footer />
    </>
  );
};

export default PrivacyAgeStep;
